<template>
  <div>
    <div class="pb-2">
      <!-- <a
        data-action="onBack"
        @click="$emit('onBack')"
      > -->
      <feather-icon
        icon="ArrowLeftIcon"
        size="25"
        @click="onBack"
      />
      <!-- </a> -->
    </div>
    <pdf-viewer
      v-if="type.toLowerCase() === 'pdf'"
      :file="path"
      :name="name"
    />
    <VueExcel
      v-if="type.toLowerCase() === 'excel' || type.toLowerCase() === 'csv' || type.toLowerCase() === 'xlsx' || type.toLowerCase() === 'xls'"
      :file="path"
      :name="name"
    />
    <VueDoc
      v-if="type.toLowerCase() === 'docx' "
      :file="path"
      :name="name"
    />
    <VuePowerPoint
      v-if="type.toLowerCase() === 'ppt' "
      :file="path"
      :name="name"
    />
    <image-viewer
      v-if="type.toLowerCase() ==='jpeg' || type.toLowerCase() ==='jpg' || type.toLowerCase() ==='png'"
      :file="path"
      :name="name"
    />
  </div>
</template>

<script>
import pdfViewer from './component/PDFView.vue'
// import tableViewer from './component/ExcelView.vue'
import VueDoc from './component/DocView.vue'
import VueExcel from './component/CsvView.vue'
import VuePowerPoint from './component/PowerPointView.vue'
import imageViewer from './component/ImageView.vue'

export default {
  components: {
    pdfViewer,
    // tableViewer,
    VueDoc,
    VueExcel,
    VuePowerPoint,
    imageViewer,

  },
  data(){
    return {
      type: '',
      path: '',
      name: '',
    }
  },
  beforeMount(){
    this.type = this.$route.params.type
    this.path = this.$route.params.path
    this.name = this.$route.params.name
  },
  methods: {
    onBack() {
      // this.$emit('onBack', true)
      this.$router.go(-1)
    },
  },
}
</script>

<template>
  <div style="height: 100%">
    <VueDoc
      id="vdp"
      :type="type"
      :value="emptyValue"
      :url="file"
      :height="80"
      language="javascript"
    />
  </div>
</template>

<script>
import VueDoc from 'vue-doc-preview'

export default {
  name: 'Demo',
  components: {
    VueDoc,
  },
  props: {
    file: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdStyle: {
        code: {
          'background-color': 'rgb(187, 255, 255)',
          'line-height': '20px',
        },
      },
      type: 'office',
      emptyValue: '',
      value: 'http://ec2-13-250-59-131.ap-southeast-1.compute.amazonaws.com/test/document.docx',
    }
  },
}
</script>

<style scoped>
</style>

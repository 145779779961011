<template>
  <div class="card image-card">
    <img
      v-for="(item,index) in images"
      :key="index"
      :src="item.url"
      class="image-view"
      @click="imageView(index)"
    >

  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      images: [
        {
          name: "image1",
          url: "https://images.unsplash.com/photo-1515165562839-978bbcf18277?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
        },
        {
          name: "image2",
          url: "https://images.unsplash.com/photo-1527295110-5145f6b148d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1431&q=80",
        },
        {
          url: "https://images.unsplash.com/photo-1542731538-f99c9f100885?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80",
        },
      ],
    }
  },
  created() {
    this.$imageViewer.images(this.images)
  },
  methods: {
    imageView(index) {
      this.$imageViewer.index(index)
      this.$imageViewer.show()
    },
  },
}
</script>

<style>
  .image-card {
    padding: 40px 80px 40px 80px;
  }
  .image-view {
    margin-bottom: 12px;
  }
</style>
